.profile-info {
  float: left;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  background-color: #fff;
  text-align: center;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 30px;
}
.profile-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  height: 300px;
}

.profile-detail img {
  float: none;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  object-fit: cover;
}
.profile-detail h3 {
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 50px;
}
.profile-detail h4 {
  margin: 0;
  color: #686868;
  font-size: 14px;
  font-weight: 500;
}
.profile-detail p {
  margin-top: -5px;
}
.profile-detail a {
  border-radius: 8px 8px 8px 8px;
  padding: 0 12px;
  color: #fff;
  height: 35px;
  line-height: 35px;
  background-color: #53d690;
}
