.forum-kontak{
    float: left;
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;
}
.forum-kontak-inner{
    float: left;
    width: 100%;
    position: relative;
    padding: 25px;
    border-bottom: 1px solid #e5e5e5;
}