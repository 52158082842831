/* --- Default Styles Card Berita --- */
.card-berita,
.post-section,
.product-feed,
.card-berita .post-bar,
.card-berita .post-topbar-berita,
.card-berita .cover-sec,
.card-berita .cover-sec img,
.card-berita .post-desc{
    width: 100%;
    float: left;
}
/* --- End Default Styles --- */


.product-feed{
    display: block;
}
.card-berita .post-bar{
    border-radius: 8px 8px 8px 8px;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
}
.card-berita .post-topbar-berita,
.card-berita .cover-sec{
    position: relative;
}
.card-berita .post-topbar-berita{
    padding: 20px 20px 15px 20px;
}
.card-berita .post-desc{
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid #e5e5e5;
}
.card-berita .post-desc h3{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
}
.card-berita .post-desc li{
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 10px;
}
.card-berita .post-desc .tags a{
    display: inline-block;
    font-size: 11px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    padding: 7px 15px;
    font-weight: 500;
}
.card-berita .post-desc>p{
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
}

.card-berita .post-desc > p a{
    font-weight: 600;
}

.job_descp {
    color: #333333;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
}