* {
  box-sizing: inherit;
}
body,
html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
body {
  line-height: 1;
  font-family: "Calibri", sans-serif;
  overflow-x: hidden;
  font-weight: 300;
}
body,
html,
#root,
h1,
h2,
h3,
h4,
h5,
p,
.ol,
ul {
  margin: 0;
}
.ol,
ul {
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
@media (max-width: 599px) {
  textarea {
    resize: none;
  }
}
.main-beranda,
.main-database,
.main-linklsm,
.profile-inner {
  padding: 60px 0px;
  width: 100%;
}

.beranda-inner,
.database-inner,
.linklsm-inner {
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.expand-button,
.less-button {
  cursor: pointer;
  font-weight: 600;
}

.less-button {
  margin-left: 5px;
}

.widget {
  float: left;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 20px;
  -webkit-box-shadow: 1px 0px 2px rgb(0 0 0 / 24%);
  -moz-box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.24);
  -ms-box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.24);
  -o-box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 1px 0px 2px rgb(0 0 0 / 24%);
}
.sd-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

/* --- Input Style --- */
.input-inner input,
.MuiAutocomplete-listbox,
.MuiAutocomplete-noOptions {
  font-size: 12px;
}
.input-inner input {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  font-weight: 600;
  border: 0;
  border: 1px solid #e5e5e5;
}
.MuiAutocomplete-hasPopupIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot,
.MuiOutlinedInput-adornedEnd,
.MuiFormControl-marginNormal {
  padding: 0 !important;
  margin: 0 !important;
}
.input-inner .autocomplete-input {
  height: 30px;
}
.autocomplete-input input {
  height: 100%;
  padding-right: 30px !important;
}
::placeholder {
  font-weight: bolder;
}
.input-login.error .MuiOutlinedInput-notchedOutline {
  border: 1px solid red !important;
  box-shadow: none !important;
}
/* --- If input without box-shadow and border
 .input-inner .MuiOutlinedInput-notchedOutline {
  border: none !important;
  box-shadow: none !important;
} 
*/
@media (max-width: 1250px) {
  .container {
    max-width: 960px;
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 720px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}
@media (max-width: 552px) {
  .container {
    max-width: unset;
  }
}
