
.search-section{
    margin: 30px 0 0 0;
}
.search-box form{
    position: relative;
    display: flex;
}
.search-box {
    padding: 0 15px;
}
.search-box input{
    width: 100%;
    height: 40px;
    background-color: #fff;
    padding: 0 15px;
    color: #b2b2b2;
    font-size: 14px;
    border: 1px solid #e5e5e5;
    -webkit-box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 4px 8px rgba(0,0,0,0.20);
    -ms-box-shadow: 0 4px 8px rgba(0,0,0,0.20);
    -o-box-shadow: 0 4px 8px rgba(0,0,0,0.20);
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    outline: none;
}
.search-box button{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #e44d3a;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    height: 100%;
    padding: 0 28px;
    border: 0;
    cursor: pointer;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}