/* --- Banner Slider Styles --- */
.card-slider {
  height: 260px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.slider-legend {
  border: 600px solid transparent;
  border-left: 560px solid rgba(52, 73, 94, 0.7);
  border-bottom: 0;
  position: absolute;
  bottom: 0;
}
.slider-content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}
.slider-content-text {
  width: 300px;
  height: 100px;
  float: left;
  position: relative;
  top: 80px;
  transform: translate(-1000px);
}
.slick-active .slider-content-text {
  -webkit-animation: slide-in-left 1.5s cubic-bezier(0.175, 0.885, 0.46, 1.07)
    1s both;
  animation: slide-in-left 1.5s cubic-bezier(0.175, 0.885, 0.46, 1.07) 1s both;
}
.slider-content-text.out {
  -webkit-animation: slide-out-left 1.5s cubic-bezier(0.6, -0.09, 0.735, 0.045)
    1s both;
  animation: slide-out-left 1.5s cubic-bezier(0.6, -0.09, 0.735, 0.045) 1s both;
}
.slider-content-text h2 {
  font-family: Calibri;
  text-transform: uppercase;
  font-size: 21px;
  text-align: left;
  margin-left: 30px;
  padding-bottom: 10px;
}
.slider-content-text p {
  font-family: Calibri;
  font-weight: normal;
  font-size: 13px;
  font-style: italic;
  text-align: left;
  margin-left: 30px;
  line-height: 1.5;
}
.slider-image,
.slider-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
/* --- End Banner Slider Styles --- */

@media (max-width: 400px) {
  .slider-content-text {
    width: 200px;
  }
}

/* --- Animation Slider --- */
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/* --- End Animation Styles --- */
