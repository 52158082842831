.cover-profile {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: block;
    overflow: hidden;
}

.cover-profile img {
    width: 100%;
    object-fit: cover;
    max-height: 315px;
}

.cover-profile .btn {
    position: absolute;
    top: 85%;
    left: 95%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.cover-profile .btn:hover {
    background-color: black;
}

a.button3 {
    position: relative;
    top: -60px;
    float: right;
    padding: 10px;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.3em;
    text-decoration: none;
    font-weight: bold;
    color: #000000;
    background-color: #f0f0f0;
    transition: all 0.2s;
}

a.button3:hover {
    background-color: #dddddd;
}

@media all and (max-width: 30em) {
    a.button3 {
        display: block;
        margin: 0.2em auto;
    }
}

.beranda-inner .title- {
    margin-bottom: 20px;
}

.title-profile {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.message-btn {
    float: right;
    margin-top: 20px;
    margin-bottom: 30px;
}

.message-btn a {
    display: inline-block;
    color: #ffffff;
    font-size: 16px;
    background-color: #e44d3a;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.message-btn svg {
    margin-right: 5px;
}
