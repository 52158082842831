/* --- Font Styles User Data --- */
.user-specs h3 {
  font-size: 24px;
}
.user-status span {
  font-size: 20px;
}
.sign-link h3 {
  font-size: 18px;
}
.user-status h4 {
  font-size: 16px;
}
.user-specs span,
.user-status a {
  font-size: 14px;
}
/* --- End Font Styles User Data --- */

.user-data,
.user-details,
.user-specs,
.user-status,
.user-status li,
.sign-link {
  float: left;
  width: 100%;
}
.user-data {
  border-radius: 10px;
  margin-bottom: 20px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
}


.user-details {
  border-radius: 10px 10px 0px 0px;
  padding-top: 40px;
}


.user-pics {
  width: 110px;
  height: 110px;
  margin: 0 auto;
  margin-bottom: -48px;
}
.user-pics img {
  border: 5px solid #fff;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  width: 100%;
}


.user-specs {
  padding: 63px 0 27px 0;
}
.user-specs h3 {
  text-transform: capitalize;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
}


.user-status li {
  /* border-bottom: 1px solid #e5e5e5; */
  border-top: 1px solid #e5e5e5;
  padding: 15px 0;
}
.user-status h4 {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 300;
}
.user-status a {
  font-weight: 600;
}


.sign-link {
  border-top: 1px solid #e5e5e5;
  padding: 18px 0 15px 0;
  line-height: 30px;
}
.sign-link p{
  margin: 0;
}
.sign-link h3 {
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
}