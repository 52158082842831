.banner-database {
  display: flex;
  /* gap: 30px; */
  align-items: center;
  padding: 8px;
  background: #006598;
  border-radius: 8px;
  color: white;
  margin-bottom: 20px;
}

.banner-database button {
  background: transparent;
  cursor: pointer;
}

.banner-database p {
  flex: 3;
}

.banner-database h3 {
  margin-right: 20px;
  flex: 1;
}


.banner-database-button {
  border-radius: 8px 8px 8px 8px;
  padding: 0 12px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  background-color: #53d690;
}



table.tableDatabase {
  border: 1px solid #AAAAAA;
  background-color: #ebebeb;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table.tableDatabase td,
table.tableDatabase th {
  border-bottom: 1px solid #AAAAAA;
  /* border-left: 1px solid #AAAAAA;
    border-right: 1px solid #AAAAAA; */
  padding: 3px 2px;
}

table.tableDatabase td {
  line-height: 1.5rem;
  padding: 0 8px;
}

table.tableDatabase thead td {
  font-size: 17px;
  font-weight: bold;
  color: white;
  padding: 5px 5px;
}

table.tableDatabase tbody tr:hover {
  background-color: #aaaaaa88;
}

table.tableDatabase tbody td {
  font-size: 13px;
  color: black;
}

table.tableDatabase tbody td a {
  color: #006598;
}

table.tableDatabase tbody td .link-namalembaga:visited {
  color: #006598;
}

table.tableDatabase tr:nth-child(even) {
  background: white;
}

table.tableDatabase thead {
  background: #006598;
}

table.tableDatabase thead th {
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
}

table.tableDatabase thead tr td th {
  background-color: #006598;
  font-size: 12px;
  font-weight: bold;
  color: #a75b5b;
}

table.tableDatabase tfoot td {
  font-size: 14px;
}

table.tableDatabase tfoot .links {
  text-align: right;
}

table.tableDatabase tfoot .links a {
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}
.tg {
  border: 1px solid #AAAAAA;
  background-color: #ebebeb;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tg thead {
  background: #1C6EA4;
  color: #FFFFFF;
  text-align: center;
}


.tg td {
  border-bottom: 1px solid #AAAAAA;
  font-family: Arial, sans-serif;
  font-size: 12px;
  overflow: hidden;
  line-height: 1.8rem;
  padding: 0 8px;
  word-break: normal;
  text-align: left;
}

.tg td a {
  color: #006598;
}

.tg td .link-namalembaga:visited {
  color: #006598;
}

.tg tbody tr:nth-child(even) {
  background: white;
}

.tg tbody tr:hover {
  background-color: #aaaaaa88;
}

.tg th {
  border-bottom: 1px solid #AAAAAA;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  font-weight: bold;
}

.tg .tg-9wq8 {
  border-color: inherit;
  text-align: center;
  vertical-align: middle
}

.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  vertical-align: top
}
.tg tbody .td-paket {
  text-align: right;
}