/* ---- Font Size ---- */
.link,
.grid-input input,
.on-off-status svg,
.on-off-status span,
.settings-menu a {
  font-size: 12px;
}
.user-settings h3 {
  font-size: 14px;
}
.grid-search svg {
  font-size: 20px;
}

/* ---- Container Navbar ---- */
.appBar {
  position: absolute;
  background: transparent;
  box-shadow: none;
}
.link-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 13px;
  align-items: center;
}
.logo-container {
  margin-right: 14px;
}
.search-container {
  width: 280px;
  position: relative;
}
.user-container {
  width: 120px;
  position: relative;
}

/* ---- Children Navbar ---- */
.link {
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding: 13px 13px;
}
.link p {
  margin: 0;
}
.link-container span {
  display: block;
}
.link:hover,
.active-link {
  border-bottom: 1px solid white;
}
.grid-search svg {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}
.user-info .link {
  padding: 0;
  text-transform: none;
}
.user-info svg {
  cursor: pointer;
}
.user-info {
  width: 120%;
  padding: 13px 10px;
  position: relative;
  display: flex;
  align-items: center;
}
.user-info img {
  margin-right: 10px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}

/* ---- Input Styles Navbar ---- */
.grid-search button {
  height: 100%;
  margin-left: -10px;
  box-shadow: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  position: absolute;
  width: 38px;
  padding: 0;
  right: 0;
  min-width: unset;
}
.grid-input,
.grid-input div {
  width: 100%;
}
.grid-input input {
  padding: 0 48px 0px 12px;
  height: 38px;
  border: 0;
  border-radius: 4px;
}
.logout-button {
  text-decoration: none;
  text-align: center;
}

/* ---- Menu Settings User ---- */
.user-settings {
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
  position: absolute;
  top: 100%;
  right: 0;
  width: 240px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  -o-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 1px 1px 6px -2px rgb(0 0 0 / 24%);
  -moz-box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.24);
  -ms-box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.24);
  -o-box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.24);
  box-shadow: 1px 1px 6px -2px rgb(0 0 0 / 24%);
  transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.user-settings.openUser {
  opacity: 1;
  visibility: visible;
  transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.user-settings h3,
.user-settings .on-off-status,
.custom-status,
.custom-status input,
.settings-menu {
  border-bottom: 1px solid #e5e5e5;
}
.user-settings h3 {
  font-weight: 600;
  padding: 15px 20px;
  margin: 0;
}
.user-settings .on-off-status {
  padding: 10px 20px;
}
.on-off-status svg,
.on-off-status span {
  font-weight: 600;
}
.custom-status {
  position: relative;
  padding: 20px;
}
.custom-status .MuiOutlinedInput-notchedOutline {
  border: none !important;
  box-shadow: none !important;
}
.custom-status .grid-search {
  position: relative;
}
.settings-menu {
  padding: 20px;
  line-height: 10px;
}
.settings-menu li {
  margin-bottom: 15px;
}
.settings-menu a {
  text-decoration: none;
  font-weight: 600;
}
.grid-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* ---- Hamburger Button ---- */
.nav-icon1 {
  position: relative;
  width: 25px;
  height: 20px;
  margin: auto 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}

.nav-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.nav-icon1 span:nth-child(1) {
  top: 0px;
}
.nav-icon1 span:nth-child(2) {
  top: 8px;
}
.nav-icon1 span:nth-child(3) {
  top: 8px;
}
.nav-icon1 span:nth-child(4) {
  top: 16px;
}
.nav-icon1.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}
.nav-icon1.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.nav-icon1.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.nav-icon1.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

@media (max-width: 1024px) {
  .link {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
  /* ---- Menu Navbar Mobile ---- */
  .link-container {
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: -90%;
    width: 100%;
    left: 0%;
    visibility: visible;
    pointer-events: none;
    -webkit-transition: all 0.26s ease-out;
    -moz-transition: all 0.26s ease-out;
    -ms-transition: all 0.26s ease-out;
    -o-transition: all 0.26s ease-out;
    transition: all 0.26s ease-out;
    padding-right: 0;
    visibility: hidden;
    opacity: 0;
  }
  .link-container.open {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
    z-index: 999999;
  }
  .search-container {
    width: 100%;
  }
  .nav-icon1 {
    visibility: visible;
    opacity: 1;
  }
  .grid-mobile {
    width: 100%;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .link-container .link {
    font-size: 10px;
  }
}

@media (max-width: 552px) {
  .logo-container {
    margin: 0;
  }
  .search-container {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .link-container {
    bottom: -20%;
    justify-content: space-between;
  }
  .link-container .link {
    padding: 13px 10px;
  }
  .link-container p {
    display: none;
  }
  .user-info {
    padding-left: 0;
  }
  .nav-icon1 {
    margin-right: 0;
  }
  .user-settings {
    right: auto;
    left: 0;
  }
  .grid-mobile {
    justify-content: space-between;
  }
}
