.feeds-container {
  background-color: white;
  border-radius: 18px;
  padding: 15px;
}
.feeds-post {
  border-bottom: 1px solid rgb(172, 171, 171, 0.5);
  padding-bottom: 10px;
}
.feeds-container > .feeds-other {
  padding-top: 10px !important;
}
.feeds-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.feeds-container .feeds-button {
  justify-content: flex-start;
  padding-left: 15px;
  background-color: #f0f2f5;
  border-radius: 18px;
}
.feeds-container .feeds-button.other {
  background-color: transparent;
  justify-content: center;
  padding: 5px;
}
.feeds-container .feeds-button.other svg {
  padding-right: 5px;
}
.feeds-container .feeds-button:hover,
.modal-feeds .close-modal-feeds:hover,
.modal-feeds .upload-feeds svg {
  background-color: #e4e6e9;
}

/* --- Style for Modal Feeds --- */
.modal-feeds {
  width: 80%;
  max-width: 500px;
  border-radius: 18px;
  overflow: hidden;
}
.modal-inner-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal-feeds .modal-header {
  border-bottom: 1px solid rgb(172, 171, 171, 0.5);
  padding: 0px 10px 20px 10px;
  text-align: center;
  margin: 0;
}
.modal-feeds .modal-body {
  height: 100%;
  display: unset;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
}
.modal-body .feeds-textarea {
  height: 100% !important;
  width: 100%;
  border: none;
  resize: none;
  font-size: 16px;
  font-family: sans-serif;
}
.modal-body textarea:active,
.modal-body textarea:focus {
  outline: none;
  border: none;
}
.modal-feeds .close-modal-feeds,
.modal-feeds .upload-feeds svg {
  border-radius: 50%;
  padding: 5px;
  background-color: #f0f2f5;
  cursor: pointer;
}
.modal-feeds .upload-feeds {
  position: relative;
  padding-top: 20px;
}
.modal-feeds .upload-feeds svg {
  position: absolute;
  top: 30px;
  right: 15px;
}
.modal-feeds .upload-feeds .MuiDropzoneArea-icon {
  top: 35%;
  right: 40%;
  transform: translate(-50%, -50%);
}
