.customModal {
  max-width: 350px;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  padding: 20px;
  overflow: hidden;
}
.customModal2 {
  max-width: 1000px;
  width: 500%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  padding: 20px;
  overflow: hidden;
}
.modal-header {
  margin-bottom: 25px;
}
.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.modal-body p {
  margin-bottom: 10px;
}
.modal-body .login-form {
  padding: 0;
}
.modal-body .login-form h3 {
  text-align: left;
}
.modal-footer {
  text-align: center;
}

/* Modal For Profile */
.modal-profile {
  max-width: 550px;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  padding: 20px;
  overflow: hidden;
}
.modal-profile .login-form {
  text-align: left;
}
.modal-profile .login-form .text-area {
  min-width: 250px;
  max-width: 100%;
  min-height: 100px;
  max-height: 150px;
}

/* Modal Syarat Ketentuan  */
.modal-body.syarat {
  justify-content: flex-start;
  text-align: left;
  height: 300px;
  padding: 20px;
  overflow: auto;
  overflow-x: hidden;
}
.modal-body.syarat ol {
  padding-left: 15px;
  margin-bottom: 10px;
}
.modal-body.syarat h1 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 10px;
}

@media (max-width: 599px) {
  .customModal,
  .modal-profile {
    max-width: unset;
    width: unset;
  }

  .modal-profile .login-form .text-area {
    min-width: unset;
    max-width: unset;
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
  }

  .modal-profile .login-form {
    padding: 0;
  }

  .modal-profile .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .modal-profile .modal-footer button {
    width: 80px;
  }
}
