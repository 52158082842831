.tabs {
  margin-bottom: 50px;
}
.tabs button {
  min-width: 100px;
}
.tabs .MuiAppBar-colorPrimary {
  background-color: white;
  color: black;
  box-shadow: none;
  border-radius: 10px;
}
.tabs .Mui-selected {
  background-color: #006697;
  color: white;
}
.tab-section svg {
  cursor: pointer;
}
.tab-section {
  text-align: center;
  word-break: break-all;
}
.tab-content {
  text-align: left;
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 30px;
}

.tab-content-tentang {
  text-align: left;
  position: relative;
  width: 100%;
  height: 100%;
  /*padding-right: 30px;*/
}

.tab-content .user-more {
  position: absolute;
  top: 0;
  right: 0;
}
.tab-content .user-more li {
  color: black;
}
.tab-content .user-more li:hover {
  color: rgb(100, 100, 100);
}

.tabs .MuiTabs-indicator {
  display: none;
}

/* --- Portfolio --- */
.tab-post.portfolio .child-grid {
  margin-bottom: 15px;
  margin-top: 15px;
}
.tab-post.portfolio .img-portfolio {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.tab-post.portfolio h3 {
  margin-bottom: 10px;
}

/* --- SDM --- */
.tab-post.sdm .btn-login {
  text-align: right;
  margin-top: 8px;
}
.tab-post.sdm button {
  border-radius: 20px;
}

.tab-post p {
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  word-break: break-word;
}
@media (max-width: 599px) {
  .tabs .MuiTabs-scrollButtonsDesktop {
    display: inline-flex;
  }
}
.rdw-editor-main{
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #F1F1F1;
  word-break: break-word;
  /* height: 150px; */
  max-height: 350px;
  padding: 10px;
}

.modal-profile .login-form{
  padding:0
 }
 
 table.blueTable {
  border: 1px solid #1C6EA4;
  background-color: #c9c9c9;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td, table.blueTable th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
table.blueTable tbody td {
  font-size: 13px;
  color: black;
}
table.blueTable tr:nth-child(even) {
  background: white;
}
table.blueTable thead {
  background: #006598;
}
table.blueTable thead th {
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
}
table.blueTable tfoot td {
  font-size: 14px;
}
table.blueTable tfoot .links {
  text-align: right;
}
table.blueTable tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

.ql-editor {
  min-height: 200px;
}

.tab-pertama {
  border-radius: 10px 0px 0px 0px;
}