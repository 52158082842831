/* --- Font Size Card About Styles --- */
.about-card span,
.about-card a{
    font-size: 14px;
}
/* --- End of Font Size Styles --- */


/* --- Default Styles Card About --- */
.about-card span,
.about-card .sign-link{
    float: left;
    width: 100%;
}
/* --- End of Default Styles --- */


.about-card{
    text-align: center;
}
.about-card img{
    padding-top: 20px;
}
.about-card span{
    margin-top: 20px;
    margin-bottom: 30px;
}
.about-card .sign-link{
    border-top: 1px solid #e5e5e5;
    padding: 18px 0 15px 0;
}
.about-card a{
    display: inline-block;
    font-weight: 500;
}