.sd-title h3 {
  margin: 0;
}

.list-lembaga {
  padding: 13px 0 30px 0;
  width: 100%;
  float: left;
}

.lembaga-detail{
    padding: 15px 20px;
    width: 100%;
    float: left;
}

.lembaga-detail img{
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    float: left;
}

.nama-lembaga{
    float: left;
    width:90%;
}

.nama-lembaga span{
    float: right;
    width:10%;
}

.nama-lembaga h4{
    margin: 0;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
}

.nama-lembaga span{
    color: #686868;
    font-size: 14px;
}

.lembaga-detail>span{
    float: right;
    margin-top: 4px;
    position: relative;
}

.lembaga-detail svg{
    color: #b2b2b2;
    width: 30px;
    height: 30px;
    border: 1px solid #e5e5e5;
    text-align: center;
    line-height: 30px;
    font-weight: 700;
    cursor: pointer;
}

.lembaga-detail svg:hover{
    background-color: #e44d3a;
    color: #fff;
}

.view-more{
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

.view-more p{
    margin:0;
    color: #006598;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}