.sdm-user ul{
    width: 100%;
    float: left;
    padding: 15px 0;
}
.sdm-user li{
    width: 100%;
    float: left;
    padding: 15px 20px;
}

.user-message-detail{
    width: auto;
    float: left;
    position: relative;
    display: flex;
    align-items: center;
}
.user-message-img{
    width: 35px;
    float: left;
    position: relative;
}
.user-message-detail img{
    position: relative;
    float: left;
    width: 100%;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
}
.user-message-info{
    float: left;
    padding-left: 13px;
}
.user-message-info h3{
    font-size: 14px;
    margin: 0;
    color: #000000;
    font-weight: 600;
}
.user-message-info p{
    color: #686868;
    margin: 0;
    font-size: 12px;
}

.sdm-user ul li>span{
    color: #686868;
    font-size: 16px;
    float: right;
    margin-top: 10px;
}
.sdm-user ul li>span img{
    padding-right: 5px;
    float: left;
}