.post-bar,
.post-header,
.post-header,
.menu-more li,
.post-content,
.post-tags,
.post-comment,
.type-post,
.job-desc,
.comment-section,
.comment-inner,
.comment-inner ul,
.comment-inner ul li,
.post-input
{
    float: left;
    width: 100%;
}
.user-desc,
.status-comment,
.status-comment li img,
.status-comment li span,
.type-desc,
.comment-box{
    float: left;
}
.post-bar{
    border-radius: 8px 8px 8px 8px;
    background-color: #fff;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
}
.post-header{
    position: relative;
    padding: 20px 20px 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.post-header img{
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    float: left;
}
.post-header .user-name{
    margin-left: 10px;
    float: left;
}
.user-name h3{
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0;
    margin-bottom: 6px;
    margin-top: 2px;
}
.user-name span{
    font-size: 14px;
}
.user-name img{
    margin-top: 2px;
    margin-right: 6px;
}

.user-more{
    position: relative;
    cursor: pointer;
}
.menu-more{
    position: absolute;
    top: 100%;
    right: 0;
    width: 130px;
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 28%);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,0.28);
    -ms-box-shadow: 0 0 10px rgba(0,0,0,0.28);
    -o-box-shadow: 0 0 10px rgba(0,0,0,0.28);
    box-shadow: 0 0 10px rgb(0 0 0 / 28%);
    padding: 20px;
    opacity: 0;
    transition: 0.5s ease-in-out;
    visibility: hidden;
    z-index: 0;
}
.menu-more.active{
    opacity: 1;
    visibility: visible;
    z-index: 999;
}
.menu-more li{
    margin-bottom: 15px;
}
.menu-more li:last-child{
    margin: 0;
}
.menu-more li a{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}

.post-content{
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid #e5e5e5;
}
.post-content h3{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
}
.post-content p{
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 20px;
}
.post-content p a{
    font-weight: 600;
}
.post-tags li {
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 10px;
}
.desc{
    margin-left: 30;
}
.post-tags li a{
    display: inline-block;
    font-size: 11px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    padding: 7px 15px;
    font-weight: 500;
}
.post-tags li button{
    display: inline-block;
    font-size: 11px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    padding: 7px 15px;
    font-weight: 500;
}
.post-comment{
    padding: 15px 20px;
}
.status-comment li{
    display: inline-block;
    margin-right: 15px;
}
.post-comment p{
    font-size: 14px;
    float: left;
    margin: 0;
    margin-top: 6px;
    margin-right: 5px;
    -webkit-transition: all 0.4s ease-in;
    -moz-transition: all 0.4s ease-in;
    -ms-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.status-comment .like svg{
    margin-right: 10px;
    font-size: 17px;
    position: relative;
}
.status-comment .like:hover{
    -webkit-transition: all 0.4s ease-in;
    -moz-transition: all 0.4s ease-in;
    -ms-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
}
.status-comment li span{
    font-size: 13px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
    font-weight: 600;
    margin-left: -25px;
}

.status-comment li .comment{
    position: relative;
    top: -5px;
}
.status-comment .comment img{
    margin-right: 10px;
    float: left;
}

.post-comment .see-comment{
    float: right;
}
.see-comment svg{
    margin-right: 7px;
}


/* --- Post All Styles --- */
.type-post{
    margin-bottom: 15px;
    padding: 0 20px;
}
.type-desc{
    margin-top: 3px;
}
.type-desc li{
    display: inline-block;
    margin-right: 15px;
}
.type-desc img{
    margin-right: 5px;
    float: left;
}
.type-desc span{
    font-size: 14px;
    float: left;
}

.type-links{
    float: right;
}
.type-links li{
    display: inline-block;
    margin-right: 7px;
}
.type-links svg{
    font-size: 24px;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 3px;
    border-radius:5px;
    line-height: 30px;
}

.job-desc{
    margin-bottom: 22px;
}
.job-desc li{
    display: inline-block;
    margin-right: 15px;
}
.job-desc a{
    text-transform: capitalize;
    padding: 5px 13px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
}


/* --- Comment Section --- */
.comment-section{
    padding: 20px;
}
.comment-section .icon-more{
    text-align: center;
    margin-bottom: 20px;
}
.comment-section .icon-more svg{
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.comment-list{
    display: table;
    padding-bottom: 30px;
}
.comment-list .user-pic{
    display: table-cell;
}
.user-pic img{
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    float: left;
}
.user-comment{
    display: table-cell;
    vertical-align: top;
    width: 100%;
    padding-left: 10px;
}
.user-comment h3{
    font-size: 16px;
    font-weight: 600;
    margin:0;
    margin-bottom: 10px;
}
.user-comment span{
    font-size: 14px;
    display: block;
    margin-bottom: 14px;
}
.user-comment img{
    position: relative;
    top: 2px;
    margin-right: 7px;
    float: left;
}
.user-comment p{
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
    line-height: 20px;
}
.user-comment .reply{
    display: unset;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.foto{
    height: 50px;
    width: 50px;
}
.foto-test{
    height: 100px;
    width: 100px;
}
.reply svg{
    margin-right: 7px;
    position: relative;
    top: 5px;
}
.comment-inner .more-reply{
    padding-left: 50px;
}

.user-image-input{
    float: left;
    width: 40px;
    margin-right: 7px;
}

.post-input{
    display: flex;
    align-items: center;
}
.comment-box{
    width: 100%;
}
.comment-box form{
    display: flex;
    align-items: center;
    width: 100%;
}
.comment-box input{
    width: 100%;
    height: 40px;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #e5e5e5;
    background-color: #efefef;
    padding-left: 10px;
    color: #b2b2b2;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    resize: none;
    outline: none;
}
.comment-box button{
    float: right;
    color: #fff;
    background-color: #e44d3a;
    height: 40px;
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
    border: 0;
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 4px;
}
@media (max-width:768px) {
    .post-comment li{
        margin-bottom: 10px;
    }
    .post-comment .see-comment{
        float: left;
        margin: 0;
    }
    .see-comment svg{
        width: 20px;
    }

    .type-links{
        margin-top: 10px;
        width: 100%;
    }
}