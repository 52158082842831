footer {
  height: 120px;
}
.footer-section {
  background-color: #fff;
  margin-top: 35px;
  float: left;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  background-color: #fff;
  margin-bottom: 10px;
}

.footer-section ul {
  float: left;
  width: 100%;
  text-align: center;
}

.footer-section ul li {
  display: inline;
  border-left: 1px solid #b2b2b2;
  padding: 0 7px;
  margin-bottom: 20px;
}

.footer-section ul li.first { 
  border-left: none; padding-left: 0; 
}

.footer-section ul li a {
  display: inline-block;
  color: #b2b2b2;
  font-size: 13px;
  font-weight: 500;
}

.copyright {
  width: 100%;
  float: left;
  margin-top: 5px;
  padding: 0 13px;
}
.copyright.foot {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.copyright p {
  float: right;
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.copyright p img {
  float: none;
  display: inline-block;
  position: relative;
  top: 3px;
  padding-right: 5px;
}
.copyright > img {
  float: left;
  margin-top: 3px;
}
@media screen and (max-width: 1200px) {
  .copyright {
    text-align: center;
  }
  .copyright p {
    margin-top: 10px;
    float: none;
  }
  .copyright > img {
    text-align: center;
    float: none;
  }
}
