.detail-berita,
.container-berita .post-tags,
.content-berita {
  margin-top: 15px;
  margin-bottom: 15px;
}
.container-berita .login-form {
  font-size: 24px;
  padding-left: 0;
}
.container-berita .login-form::after,
.container-berita .login-form::before {
  content: none;
}
.container-berita .post-tags h4 {
  display: inline-block;
  margin-right: 10px;
}
.container-berita {
  padding: 25px;
  width: 100%;
  background-color: white;
  border-radius: 18px;
}
.header-berita img {
  width: 100%;
}

.isi-berita {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.detail-berita {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-berita > p {
  font-style: italic;
}
.date-berita {
  display: flex;
  align-items: center;
}
.date-berita p {
  margin-left: 5px;
}

.content-berita span {
  font-weight: bold;
}

.content-berita h4 {
  padding: 10px 0;
}

.post__description h4 {
  padding: 20px 0;
}

.post__description h3 {
  padding: 30px 0;
}

.post__description h2 {
  padding: 40px 0;
}

.post__description h1 {
  padding: 50px 0;
}

.post__description p {
  line-height: 24px;
}