.card-profile,
.user-profile-page,
.user-status {
  width: 100%;
  float: left;
}
.card-profile {
  margin-top: -20px;
}
.user-profile-page {
  background-color: #fff;
  margin-bottom: 20px;
  text-align: center;
  margin-top: -40px;
  border-radius: 0px 0px 10px 10px;
}
.user-profile-page .user-img {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 28px;
  margin-top: -95px;
  margin-left: 10px;
  position: relative;
}
.user-profile-page .user-img img {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  float: none;
  border: 5px solid #fff;
}
.user-status-job {
  padding-bottom: 27px;
}
.user-status-job ul {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.user-status-job li {
  display: inline-block;
  margin-right: 17px;
  margin-bottom: 20px;
}
.user-status-job li img {
  float: left;
}

.user-social {
  float: left;
  width: 100%;
  text-align: left;
}
.user-social li {
  float: left;
  width: 100%;
  padding: 15px 15px;
  border-bottom: 1px solid #e5e5e5;
}
.user-social li a {
  float: left;
  width: 100%;
  color: #51a5fb;
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 599px) {
  .user-profile-page .user-img {
    margin-top: 0;
  }
}
.user-social li a{
    float: left;
    width: 100%;
    color: #51a5fb;
    font-size: 13px;
    font-weight: 400;
}
.img{
    width: 200px;
    height: 200px;
}

a.button4 {
  position: relative;
  top: -10px;
  left: -40px;
  background-color: transparent;
  color: black;
}

a.button3:hover {
  background-color: #dddddd;
}

@media all and (max-width: 30em) {
  a.button4 {
    margin: 0.2em auto;
  }
}