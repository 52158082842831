.sd-title h3{
    margin:0;
}
.sd-list{
    float: left;
    width: 100%;
    padding: 20px 20px;
}
.sd-info{
    margin: 0;
}
.sd-info p{
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}
.download-sd{
    float: right;
    margin-top: 20px;
}
.download-sd a{
    color: #fff;
    background-color: #51a5fb;
    text-transform: capitalize;
    display: inline-block;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
    position: relative;
    top: -3px;
}