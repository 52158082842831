/* --- Default Slider Styles --- */
.post-slider,
.card-slider,
.post-profile-lembaga {
  float: left;
  width: 100%;
}
/* --- End Default Styles --- */

.post-slider {
  border-radius: 8px 8px 8px 8px;
  padding: 23px 20px;
  border-top: 0px solid #006598;
  border-left: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 0 1px rgb(0 0 0 / 24%);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.24);
  -ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0.24);
  -o-box-shadow: 0 0 1px rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 1px rgb(0 0 0 / 24%);
  margin-bottom: 20px;
  overflow: hidden;
}

.post-profile-lembaga {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.header-profile-lembaga {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.post-profile-lembaga h3 {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  float: left;
}

/* --- Styles Dots Slider --- */
.slider-banner .slick-dots {
  display: block;
  width: 120px;
  height: 10px;
  position: absolute;
  bottom: 20px;
  z-index: 99;
  left: 30px;
}
.slider-banner .slick-dots li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #333;
  float: left;
  margin-right: 5px;
  cursor: pointer;
}
.slider-banner .slick-dots .slick-active {
  border-radius: 50%;
  background: #f39c12;
  position: relative;
  -webkit-animation: on 30s infinite;
  -moz-animation: on 30s infinite;
  animation: on 30s infinite;
}
.slider-banner .slick-dots button,
.slider-banner .slick-dots button::before {
  opacity: 0;
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0;
}
/* --- End Styles Dots ---*/

/* --- Arrows Slider Styles ---*/
.slider-profile-lembaga .slick-next::before,
.slider-profile-lembaga .slick-prev::before {
  font-size: 32px;
  opacity: 1;
  color: #858585;
  z-index: 999;
}
.slider-profile-lembaga .slick-prev {
  left: -15px;
  z-index: 999;
}
.slider-profile-lembaga .slick-next {
  right: -5px;
}
/* --- End Arrows Slider Styles ---*/
