.register {
  padding-top: 30px;
  padding-bottom: 30px;
}
.login-form.register::before,
.login-form.register::after {
  content: none;
}

.login-form .stepper {
  padding-left: 0;
  padding-right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid rgb(180, 180, 180);
  border-bottom: 1px solid rgb(180, 180, 180);
}

.login-form .stepper .MuiSvgIcon-root {
  font-size: 24px;
}

.struktur h3 {
  margin-bottom: 0;
}
.struktur .MuiDropzoneArea-root {
  min-height: 50px;
  margin-top: 30px;
}
.struktur .MuiDropzoneArea-root p {
  margin: 0;
  margin-top: 10px;
  font-size: 12px;
}
.struktur .MuiDropzoneArea-root svg {
  font-size: 12px;
  width: 25px;
  height: 25px;
}
.struktur .MuiDropzonePreviewList-root {
  justify-content: center;
  margin-bottom: 10px !important;
}

.kompetensi h3 {
  display: inline-block;
}
.kompetensi h3 svg {
  position: absolute;
  right: -20px;
  top: 3px;
}

.text-area {
  max-width: 100%;
  max-height: 250px;
  min-height: 150px;
  min-width: 250px;
  width: 100%;
  padding: 20px;
}

.kompetensi .MuiDropzoneArea-root {
  min-height: 175px;
}

.checkbox-bidangKegiatan label {
  display: flex;
  margin-bottom: 7px;
}

.add-identitas {
  text-align: center;
}
.add-identitas h2 {
  margin-top: 25px;
  margin-bottom: 10px;
}
.add-identitas .MuiSvgIcon-root {
  font-size: 24px;
  cursor: pointer;
}
@media (max-width: 552px) {
  .login-form.register {
    padding-left: 25px;
    padding-right: 25px;
  }
}
