.sukses-page {
  width: inherit;
  height: inherit;
  min-height: 100vh;
  height: 100%;
  background-color: #006598;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sukses-page-inner {
  background-color: white;
  width: 500px;
  padding: 30px;
  border-radius: 18px;
}
