.login {
  min-height: 100vh;
}
.login-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 0px 20px 0px;
}
.login-page-inner {
  width: 870px !important;
  border-radius: 4px;
  position: relative;
}

.login-page-inner::before {
  content: "";
  position: absolute;
  top: -16px;
  left: 56px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}
.login-page-inner::after {
  content: "";
  position: absolute;
  top: -37px;
  left: 43px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}

/* Login Content Styles */

/* Grid Left*/
.grid-left {
  padding: 70px 20px 92px 20px;
  border-right: 1px solid #f0f0f0;
}
.grid-left .cm-info {
  padding-left: 45px;
  margin-bottom: 120px;
}
.grid-left .cm-info p {
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}
.grid-left .cm-img img {
  padding-left: 10px;
  width: 100%;
}
.grid-left .cm-info img {
  margin-bottom: 30px;
}

/* Grid Right */
.grid-right {
  padding-left: 15px;
  padding-right: 15px;
}
.login-container {
  width: 100%;
  padding: 30px 0;
  position: relative;
}
.login-control {
  text-align: right;
  padding-right: 50px;
  margin-bottom: 70px;
  margin-top: 0;
}
.login-control li {
  display: inline-block;
  margin-right: -5px;
}
.login-control li:first-child button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.login-control li:last-child button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.login-control .MuiButton-contained {
  font-size: 12px;
  padding: 7px 10px;
  font-weight: 600;
  box-shadow: none;
  text-transform: none;
  transition: 0.5s ease-in-out;
}
.login-form {
  padding-right: 75px;
  padding-left: 60px;
}
.login-form::before {
  content: "";
  position: absolute;
  bottom: -15px;
  right: 70px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}
.login-form::after {
  content: "";
  position: absolute;
  bottom: -40px;
  right: 55px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}
.login-form h3 {
  position: relative;
  padding-bottom: 10px;
  margin: 0;
  margin-bottom: 20px;
}
.login-form h3::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  bottom: 0;
  left: 0;
}
.login-form .input-login {
  margin-top: 10px;
  width: 100%;
}
.login-form .MuiOutlinedInput-adornedStart {
  font-size: 12px;
}
.login-form .MuiOutlinedInput-input {
  padding: 0 !important;
  height: 40px;
}
.login-form .MuiSvgIcon-root {
  font-size: 16px;
}
.login-form .password-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.login-form .MuiAutocomplete-endAdornment {
  top: calc(50% - 10px);
}

.password-control p {
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}
.login-form .remember-me {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.remember-me span {
  padding: 0px;
  margin-right: 5px;
}
.login-form .btn-login {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-login button {
  padding: 12px 27px;
  font-size: 14px;
  line-height: normal;
  text-transform: none;
}

/* Form Mendaftar -> Daftar */
.login-tab {
  width: 100%;
  border: 1px solid #e5e5e5;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 20px;
  position: relative;
  margin-top: -30px;
}
.login-tab .icon-arrow {
  position: absolute;
  top: 15px;
  left: 13px;
  color: #000;
  font-size: 18px;
}
.login-tab .login-control {
  display: flex;
  justify-content: center;
  margin: 0;
}
.login-tab h2 {
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
}
.login-form .filter-header p,
.login-form .label-header p:last-child {
  cursor: unset;
}
/* Footer Login Styles */
.footer-login {
  margin-top: 50px;
  max-width: 1170px;
}
.footer-login a {
  text-decoration: none;
  font-size: 12px;
}
.footer-login li {
  display: inline-block;
  padding: 0 10px;
  border-right: 1px solid #ffffff;
}
.footer-login li:last-child {
  border-right: none;
}
.footer-login p {
  font-size: 12px;
}
.footer-login p img {
  padding-right: 5px;
  position: relative;
  top: 3px;
}

.gallery-pt {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.gallery-pt img {
  width: 100%;
  height: 174px;
  object-fit: cover;
  transition: all 0.4s ease-in;
}
.gallery-pt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.4s ease-in;
  visibility: hidden;
}
.gallery-pt:hover::before,
.gallery-pt:hover svg {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.gallery-pt svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  transition: all 0.4s ease-in;
  opacity: 0;
  color: white;
  width: 25%;
  cursor: pointer;
}
/* Error Message */
.error-msg {
  opacity: 0;
  margin-bottom: 0px;
  margin-top: 5px;
  padding-left: 5px;
  font-size: 14px;
  display: block;
  -webkit-animation: fade-in-top 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-in-top 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.exit {
  -webkit-animation: fade-out-top 0.35s cubic-bezier(0.645, 0.045, 0.355, 1)
    both;
  animation: fade-out-top 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) both;
}

.active-form {
  animation: fade 1.5s both;
  -webkit-animation: fade 1.5s both;
}
.hide {
  display: none;
  transition: 0.5s ease-in-out linear;
}

/* Animation for error message */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
}
@keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
}

/* Responsive Web */
@media (max-width: 1200px) {
  .footer-login {
    flex-direction: column;
    max-width: 960px;
    text-align: center;
  }
  .footer-login ul {
    padding: 0;
  }
  .footer-login p {
    text-align: center;
    margin-top: 15px;
  }
}

@media (max-width: 992px) {
  .login-page {
    padding: 100px 20px 20px 20px;
  }
  .login-page-inner {
    width: 100% !important;
  }
  .grid-left {
    padding: 40px 35px 0 35px;
    border: none;
  }
  .grid-left .cm-info {
    margin-bottom: 0;
    padding-left: 0;
  }
  .grid-right .login-control {
    padding-left: 20px;
    padding-right: 0;
    text-align: left;
  }
  .login-tab .login-control {
    padding: 0;
  }
  .grid-right .login-form {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .login-page {
    padding: 60px 20px 20px 20px;
  }
}
