.pagination {
  display: flex;
}
.pagination .page-item .page-link {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
  cursor: pointer;
}
.pagination .page-item.active .page-link {
  background-color: #006598;
  color: white;
  border: 1px solid #006598;
}
.pagination .page-item:hover:not(.active) .page-link {
  background-color: #ddd;
}
