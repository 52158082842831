/* --- Default Filter Styles ---*/
.filter-data,
.filter-header,
.filter-header-expend,
.filter-content {
  width: 100%;
  float: left;
}
/* --- End Default Filter Styles ---*/


/* --- Font Styles Filter --- */
.filter-header h3 {
  font-size: 18px;
}
.filter-header-expend h3 {
  font-size: 18px;
}
.label-header svg {
  font-size: 16px;
}
.filter-header p,
.label-header p:last-child {
  font-size: 14px;
}
.filter-header-expend p,
.label-header p:last-child {
  font-size: 14px;
}
/* --- End Font Styles Filter --- */


.filter-data {
  border-radius: 10px 10px 0px 0px;
}
.filter-header {
  padding: 23px 20px;
  border-bottom: 1px solid #e5e5e5;
}
.filter-header h3 {
  float: left;
  font-weight: 600;
  margin: 0;
}
.filter-header p,
.label-header p:last-child {
  float: right;
  margin: 0;
  margin-top: 4px;
  cursor: pointer;
  font-weight: normal;
}

.filter-header-expend {
  padding: 23px 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 27px;
}
.filter-header-expend h3 {
  float: left;
  font-weight: 600;
  margin: 0;
}
.filter-header-expend p,
.label-header p:last-child {
  float: right;
  margin: 0;
  margin-top: 4px;
  cursor: pointer;
  font-weight: normal;
}

.label-header div {
  font-weight: normal;
}

.filter-content {
  padding: 0 20px;
}
.label-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 8px 0;
  margin-bottom: 10px;
  font-weight: 600;
}
.label-header svg {
  cursor: pointer;
}

.input-inner {
  margin-bottom: 27px;
}