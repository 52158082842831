/* --- Default Styles Card Article --- */
.forum-question,
.forum-question .user-question{
    float: left;
    width: 100%;
}
/* --- End of Default Styles --- */


.forum-question .user-question{
    position: relative;
    padding: 25px;
    border-bottom: 1px solid #e5e5e5;
}
.forum-question .question-detail{
    float: left;
    width: 90%;
    padding-left: 15px;
}
.question-detail h3{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}
.pre-img img{
    float: left;
    margin-right: 1rem;
}
.question-detail p{
     font-size: 14px;
     line-height: 22px;
}