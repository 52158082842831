.post-topbar{
    float: left;
    width: 100%;
    border-radius: 8px 8px 8px 8px;
    padding: 23px 20px;
    background-color: #fff;
    border-top: 0px solid #006598;
    border-left: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    -webkit-box-shadow: 0 0 1px rgb(0 0 0 / 24%);
    -moz-box-shadow: 0 0 1px rgba(0,0,0,0.24);
    -ms-box-shadow: 0 0 1px rgba(0,0,0,0.24);
    -o-box-shadow: 0 0 1px rgba(0,0,0,0.24);
    box-shadow: 0 0 1px rgb(0 0 0 / 24%);
    margin-bottom: 20px;
}
.post-topbar .cover{
    float: left;
    width: 100%;
    position: relative;
}
.post-topbar img{
    width: 100%;
    float: left;
}