.main-beranda {
  padding: 60px 0px;
  width: 100%;
}
.beranda-inner {
  padding-left: 15px;
  padding-right: 15px;
}

.post-profile-lembaga .slick-slide {
  display: flex !important;
  justify-content: center;
}
.post-profile-lembaga .profile-detail {
  padding-bottom: 0;
  width: 170px;
  height: 250px;
}
.post-profile-lembaga .profile-detail a {
  width: 100%;
  color: #000000;
  background-color: transparent;
  font-size: 14px;
  text-transform: capitalize;
  padding: 15px 0;
  border-top: 1px solid #ececec;
  font-weight: 600;
  line-height: normal;
  border-radius: 0;
  height: unset;
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.post-profile-lembaga .profile-detail a:hover {
  color: white;
  background-color: #e44d3a;
}

@media (max-width: 768px) {
  .post-profile-lembaga .profile-detail {
    width: 100%;
  }
  .post-profile-lembaga .slick-slide {
    display: block !important;
  }
}
