/* Background Colour */
.login,
.link-container.open,
.user-details {
  background-color: #006598;
}
body {
  background-color: #eeeeee;
}
.login-page-inner,
.grid-input input,
.user-settings,
.user-data,
.menu-more,
.comment-section,
.widget,
.forum-question,
.card-berita .post-bar,
.post-slider,
.filter-data,
.login-page-inner::before,
.login-page-inner::after,
.login-form::before,
.login-form::after {
  background-color: white;
  border-radius: 10px;
}
.login-control .MuiButton-contained,
.post-tags li a,
.card-berita .post-desc .tags a {
  background-color: #e5e5e5;
}
.login-control .MuiButton-contained,
.post-tags li button,
.card-berita .post-desc .tags a {
  background-color: #e5e5e5;
}
.custom-status input,
.input-inner input,
.MuiAutocomplete-listbox,
.MuiAutocomplete-noOptions {
  background-color: #f2f2f2;
}
.grid-right .active,
.btn-login button {
  background-color: #006598;
  color: white;
}
.grid-right .active:hover,
.btn-login button:hover {
  background-color: #0f6d9c;
}
.custom-status button {
  background-color: #e44d3a;
  color: white;
}
.custom-status button:hover {
  background-color: #e2604f;
}
.status-comment li span{
    background-color: #009FEC;
}
.job-desc a{
    background-color: #53d690;
}

/* Font, Text Colour */
.footer-login a,
.footer-login li,
.footer-login p,
.hidden-msg,
.link,
.status-comment li span,
.job-desc a,
.slider-content-text h2,
.slider-content-text p {
  color: white;
}
.password-control p,
.user-settings h3,
.settings-menu a,
.user-specs h3,
.user-status span,
.sign-link a,
.post-content h3,
.user-name h3,
.user-comment h3,
.question-detail h3,
.card-berita .post-desc h3,
.filter-header h3 {
  color: #000000;
}
.error-msg {
  color: red;
}
.grid-search svg,
.user-status a,
.about-card a
 {
  color: #006598;
}
.post-comment p,
.user-more,
.user-name span,
.comment-section svg,
.user-comment span{
    color: #b2b2b2;
}
.post-tags li a,
.type-desc span,
.card-berita .post-desc .tags a,
::placeholder{
    color: #797979;
}
.post-tags li button,
.type-desc span,
.card-berita .post-desc .tags a,
::placeholder{
    color: #797979;
}
.post-content p a,
.expand-button,
.less-button,
.card-berita .post-desc > p a{
    color: #009FEC;
}
.user-settings .on-off-status,
.input-inner .on-off-status,
.user-specs span,
.user-status h4,
.menu-more li a,
.user-comment p,
.about-card span,
.sd-info p,
.filter-header p,
.label-header p:last-child{
  color: #686868;
}
.post-content p,
.question-detail p,
.card-berita .post-desc>p{
    color: #333333;
}



/* Aset-aset */
.login-form h3::before {
  background-color: #e44d3a;
}
.type-links .bookmark{
    background-color: #53d690;
}
.type-links .envelope{
    background-color: #e44d3a;
}

.status-comment .like.active,
.status-comment .like:hover,
.status-comment .comment:hover,
.reply.active svg,
.reply svg:hover,
.reply.active,
.reply:hover{
    color: #e44d3a;
}

.type-links svg{
    color: #fff;
}